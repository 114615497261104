import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import erModel from './ER-model.png';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rolling-stock-api-beta"
    }}>{`Rolling-Stock API (beta)`}</h1>
    <p>{`Rolling stock is a database that contains rolling material. This may be a train carriage or a bus.
It may also be a airplane or a ferry, so "rolling" is somewhat misleading.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`You need a valid token to use this service. In order to issue a valid token you must have a client_id and
client_secret(the mechanisms for obtaining these are not in place yet).`}</p>
    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <p>{`This guide will go through important concepts and the overall model of Rolling stock to better understand and make use
of this service.`}</p>
    <p>{`For simplicities sake, trains are used as a reference to easier understand the concepts.
Though this example is not limited by trains and can be expanded to the case of a bus as an example.`}</p>
    <figure>
    <img src={erModel} alt="Entity relationship model" />
    <figcaption>
        Entity relationship model is divided into two concepts - physical structure and applied structure.
    </figcaption>
    </figure>
    <h2 {...{
      "id": "physical-structure"
    }}>{`Physical structure`}</h2>
    <p>{`The physical structure describes a carriage visually for a given operator and the eventual modifications they have made.
In practice it is usually the same or based on a litra for norwegian trains.`}</p>
    <h3 {...{
      "id": "basic-element"
    }}>{`Basic Element`}</h3>
    <p>{`A basic element describes a physical carriage. For example the adaption of litra A7-1 by VY.`}</p>
    <p>{`A basic element is identified by series and version. The series is assigned by the user. The version is set to 1
when the element is created. The version is bumped when new versions with the same series are created.`}</p>
    <h3 {...{
      "id": "basic-element-properties"
    }}>{`Basic Element Properties`}</h3>
    <p>{`Basic element properties describe general properties of the carriage. For example if there is wifi or a wheelchair lift.`}</p>
    <h3 {...{
      "id": "basic-seat"
    }}>{`Basic Seat`}</h3>
    <p>{`A basic seat is a seat in a basic element. It may also used for other objects like space for a standing person or a bicycle.`}</p>
    <h3 {...{
      "id": "basic-seat-properties"
    }}>{`Basic Seat Properties`}</h3>
    <p>{`Basic seat properties describe the fundamental properties of a basic seat. This should not be used to determine usage properties.`}</p>
    <h2 {...{
      "id": "applied-structure"
    }}>{`Applied structure`}</h2>
    <p>{`The applied structure refines an occurrence of the physical structure by adding properties that determines its application.
This could for example be the operators own version of litra A7-1 as it is being used day trains on the Dovre line.`}</p>
    <h3 {...{
      "id": "logical-element"
    }}>{`Logical Element`}</h3>
    <p>{`A logical element describes a basic element as it would be used in a given application.
For example be the operators own version of litra A7-1 as it is being used day trains on the Dovre line.
The accompanying basic element is identified by database id or the series/version.`}</p>
    <p>{`A logical element is identified by series and version. The series is assigned by the user. The version is set to 1
when the element is created. The version is bumped when new versions with the same series are created.`}</p>
    <h3 {...{
      "id": "logical-seat-group"
    }}>{`Logical Seat Group`}</h3>
    <p>{`A logical seat group describes a logical compartment. This is used to give a more detailed management of automatic seat allocation.
It could for example be desirable that some products manage automatic seat allocation that are closer to each other than other products.
The case of premium seats, travelers are grouped into four, but on standard seats it's sufficient enough that the travelers are allocated to the same compartment.
Logical seat groups can be nested.`}</p>
    <h3 {...{
      "id": "logical-seat"
    }}>{`Logical Seat`}</h3>
    <p>{`A logical seat is based on a basic seat with added properties that specify its application.`}</p>
    <h3 {...{
      "id": "logical-seat-properties"
    }}>{`Logical Seat Properties`}</h3>
    <p>{`Logical seat properties determines properties of a seat for a given application.`}</p>
    <h2 {...{
      "id": "consolidated-logical-element"
    }}>{`Consolidated logical element`}</h2>
    <p>{`A consolidated logical element contains the seats from a logical element. Each such seats will have a combination of basic element properties,
basic seat properties and logical seat properties.`}</p>
    <p>{`Properties that have a single value do not overlap. They will be picked from the corresponding value in one of the property sets.`}</p>
    <p>{`Properties that have a set of values, will contain the sum of the corresponding values in all the property sets.`}</p>
    <ul>
      <li parentName="ul">{`fareClass. From logical seat properties.`}</li>
      <li parentName="ul">{`groupBooking. From logical seat properties.`}</li>
      <li parentName="ul">{`accommodationFacility. From basic seat properties.`}</li>
      <li parentName="ul">{`seatAttachment. From basic seat properties.`}</li>
      <li parentName="ul">{`seatPosition. From basic seat properties.`}</li>
      <li parentName="ul">{`relativeDirection. From basic seat properties.`}</li>
      <li parentName="ul">{`nuisanceFacilities. Sum of values from basic element properties and logical seat properties.`}</li>
      <li parentName="ul">{`passengerCommsFacilities: From basic element properties.`}</li>
      <li parentName="ul">{`sanitaryFacilities: From basic element properties.`}</li>
      <li parentName="ul">{`mobilityFacilities: From basic element properties`}</li>
      <li parentName="ul">{`baggageType. Sum of values from  basic element properties, basic seat properties and logical seat properties.`}</li>
    </ul>
    <h1 {...{
      "id": "api-reference"
    }}>{`API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/rolling-stock/v3/api-docs"
      }}>{`See the API reference on Swagger Petstore`}</a></p>
    <Swagger url="https://api.staging.entur.io/rolling-stock/v3/api-docs" fallback="https://petstore.swagger.io/?url=https://api.staging.entur.io/rolling-stock/v3/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      